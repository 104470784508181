import React from 'react'
import image from '../images/w_onama_img.jpg'
import slika2005 from '../images/o_nama_2005.png'
import slika2011 from '../images/o_nama_2011.png'
import slika2014 from '../images/o_nama_2014.png'
import slika2018 from '../images/o_nama_2018.png'

class AboutUs extends React.Component{
    render(){
        return(
            <section id="about_us_page" className="hero about-us-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-right padding-0">
                          
                        </div>{/* STATIC PART END */}

                        <div className="column is-8 content-part is-left padding-0">
                            <div className="inner">
                            <img className="_image" src={image} alt="Catic Company" />
                           
                            <p className="subtitle">Ćatić DOO je porodična firma koja je počela sa radom davne 1986 god. u privatnom vlasništvu gospodina
Osmana Ćatića i bavila se transportom robe. U periodu od 1986. do 2005 god. ova naizgled mala
porodična firma dobija svoj prostor i značajno mesto u svesti potrošača.</p>
                            <div className="columns">
                                <div className="column padding-0">
                                    <img src={slika2005} alt="Ćatic Company"/>
                                </div>
                                <div className="column padding-0">
                                    <p className="subtitle">
                                        <span className="colorRed">2005.</span> Firma menja svoj prvobitni naziv i postaje Ćatić Company DOO firma kojoj proizvodnja postaje
primarna delatnost, i koja će u daljem periodu opravdati poverenje dato potrošačima.
                                    </p>
                                    <span className="zigzag"></span>
                                    <p className="subtitle">
                                        <span className="colorRed">2007.</span> je godina kada su potrošači zaista zavoleli te okrugle vafl rolnice koje su ih pozicionirale i izdvojile od drugih brendova koji proizvode slične proizvode. To ujedno postaje primarna delatnost proizvodnje u firmi Ćatić company DOO.
                                    </p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column padding-0">
                                <p className="subtitle">Naredne godine su od izuzetnog značaja za Ćatić Company DOO.</p>
                                    <span className="zigzag"></span>
                                <p className="subtitle">
                                    <span className="colorRed">2009.</span> implementira se HACCP sistem, <br/>
                                    <span className="colorRed">2010.</span> uvodi se ISO Certifikat 9001:2008 <br/>
                                    <span className="colorRed">2011.</span> Otpočinje proces proizvodnje mlečnog i kakao krema čime se proširuje proizvodni asortiman ove firme.
                                    Zahvaljujući tome Ćatić Company DOO biva nagrađena na 80.tom Međunarodnom
poljoprivrednom sajmu koji se održava u Novom Sadu i dobija plaketu za kvalitet konditorskih proizvoda
a Waltz cream dobija zlatnu medalju za kvalitet. To je bila velika nagrada i potvrda na kojoj se predano
radilo godinama unazad.
                                </p>
                                </div>
                                <div className="column padding-0">
                                    <img src={slika2011} alt="Ćatic Company"/>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column padding-0">
                                    <img src={slika2014} alt="Ćatic Company"/>
                                </div>
                                <div className="column padding-0">
                                <p className="subtitle">Asortiman se nastavio širiti. Potrošači su jedva čekali šta će im se novo ponuditi.</p>
                                    <span className="zigzag"></span>
                                <p className="subtitle">
                                    <span className="colorRed">2014.</span> Počinje se sa proizvodnjom Waltz rolls. HALAL sertifikat obuhvata sve Waltz proizvode koji su
proizvodili do tada (Waltz roll, Waltz cream i Waltz block). <br/>
                                    <span className="colorRed">2015.</span> Uvodi se i certifikuje EN ISO 22000. To je bio veliki iskorak koji je Ćatić Company DOO napravila. <br/>
                                    <span className="colorRed">2017.</span> Ćatić Company DOO biva opet nagrađena. Ovaj put potvrda je stigla od medjunarodne grupe „BISNODE“ čime je ušla u grupu od pet najbrže rastućih firmi u Republici Srbiji.
                                </p>
                                </div>
                            </div>

                            <p className="subtitle">Danas se pod brendom Waltz proizvodi Cream rolls, Choko cream rolls, Waltz cream I Waltz block. Waltz
proizvodi su rasprostranjeni na tržištu bivše Jugoslavije tj. Srbije, Crne Gore, Bosne i Hercegovine,
Hrvatske, Makedonije. Osim toga proizvodi se mogu naći i na policama Bugarske, Austrije, Kanade, Iraka,
Palestine.</p>
<span className="zigzag"></span>
                            <p className="subtitle">Ova u početku mala porodična firma danas broji preko 120 stalno zaposlena radnika. Iz proizvodnog
pogona dnevno se sprema preko 30 tona proizvoda i šalje dalje kanalima distribucije da bi na vreme stiglo
do polica marketa i bilo dostpuno krajnjim potrošačima.</p>
<span className="zigzag"></span>
<p className="subtitle">Put razvoja Ćatić Company utemeljen je na porodičnim vrednostima, zadovoljstvu zaposlenih (briga o
kadru, njihovim potrebama, redovnom isplatom zarada) čime u krajnjem dovodi do sretnih i zadovoljnih
radnika.</p>
<span className="zigzag"></span>
<p className="subtitle">Danas je Ćatić Company DOO savremeno organizovana i tržišno orjentisana kompanija koja
neprestano ulaže u kvalitet i razvoj. Ispunjavanje svetskih zahteva i standarada u pogledu bezbednosti
hrane, jedinstvena receptura kao i savremena tehnologija osnova je politke Ćatić Company DOO.</p>
                            <img src={slika2018} alt="Ćatic Company"/>

                            <div class="videoWrapper">
                                <iframe src="https://player.vimeo.com/video/313909288" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>

                            </div>
                        </div>{/*CONTENT PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default AboutUs